
import moment from "moment";

import { getPropertyAddress } from "~/helpers/property-helpers.js";

import transactions from "~/graphql/Person/Transactions.gql";

export default {
  inheritAttrs: false,

  data() {
    return {
      openStateByYear: [], // [index]: true/false/undefined
    };
  },

  apollo: {
    transactions: {
      query: transactions,

      update(orgData) {
        const data = structuredClone(orgData);

        let transactionsByInfoId = new Map();

        data.person?.ownerChanges.forEach((ownerChange) => {
          const transactionInfoId = ownerChange.transactionInfoId;

          if (transactionsByInfoId.has(transactionInfoId)) {
            transactionsByInfoId.get(transactionInfoId).push(ownerChange);
          } else {
            transactionsByInfoId.set(transactionInfoId, [ownerChange]);
          }
        });

        let transactionsByYear = new Map();

        transactionsByInfoId.forEach((transactionGroup) => {
          // might seem excessive to call moment on each property, but dates are in UTC, and we need to handle timezone offsets
          const year = moment.utc(transactionGroup[0].handoverDate).year();

          if (transactionsByYear.has(year)) {
            const a = transactionsByYear.get(year);

            a.push(transactionGroup);
          } else {
            transactionsByYear.set(year, [transactionGroup]);
          }
        });

        // ensure years are sorted from latest year to oldest year
        const sorted = new Map([...transactionsByYear.entries()].sort((a, b) => b[0] - a[0]));

        if (sorted.size > 0) {
          return sorted;
        } else return null;
      },

      error(error) {
        console.error(error);
      },

      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },

  computed: {
    loading: function () {
      return this.$apollo.queries.transactions.loading;
    },
  },

  methods: {
    isStateOpen(year) {
      return this.openStateByYear[year] === true;
    },

    toggleOpenState(year) {
      const state = this.openStateByYear[year];

      this.$set(this.openStateByYear, year, state === undefined ? true : !state);
    },

    getTransactionsByYear(year) {
      let transactions = this.transactions.get(year);

      if (!this.isStateOpen(year)) {
        transactions = [this.transactions.get(year)[0]];
      }

      return transactions;
    },

    getTransactionsByYearLength(year) {
      return this.transactions.get(year).length;
    },

    getTransactionProperties(ownerChange) {
      const out = [];

      ownerChange.forEach((o) => {
        const property = o.property;
        const bfeNumber = property.bfeNumber;
        const address = getPropertyAddress(o.property);

        if (address != null) {
          out.push({ bfeNumber, address });
        } else {
          out.push({ bfeNumber });
        }
      });

      return out;
    },

    getTransactionInfo(transaction) {
      return transaction[0].transactionInfo;
    },

    getSellersChanges(transactions) {
      const a = [];

      transactions.forEach((t) => {
        t.ownershipChanges?.forEach((o) => {
          if (o.seller !== null && a.filter((aa) => aa.id === o.seller.id).length === 0) {
            a.push(o.seller);
          }
        });
      });

      return a;
    },

    getBuyersChanges(transactions) {
      const a = [];

      transactions.forEach((t) => {
        t.ownershipChanges?.forEach((o) => {
          if (o.buyer !== null && a.filter((aa) => aa.id === o.buyer.id).length === 0) {
            a.push(o.buyer);
          }
        });
      });

      return a;
    },

    calculateOwnershipShare(ownershipChange) {
      let share = 0;

      if (ownershipChange.buyer != null && ownershipChange.buyerShareDenominator != null && ownershipChange.buyerShareNumerator != null) {
        return ownershipChange.buyerShareNumerator / ownershipChange.buyerShareDenominator;
      } else if (ownershipChange.seller != null && ownershipChange.sellerShareDenominator != null && ownershipChange.sellerShareNumerator != null) {
        return ownershipChange.sellerShareNumerator / ownershipChange.sellerShareDenominator;
      }

      return share;
    },

    getTransferTypeTranslationValue(transferType) {
      switch (transferType) {
        case "Almindelig fri handel":
          return this.$t("COMPANY_TRANS_HANDOVER_FREE_MARKET");
        case "Anden overdragelse":
          return this.$t("COMPANY_TRANS_HANDOVER_OTHER");
        case "Interessesammenfald":
          return this.$t("COMPANY_TRANS_HANDOVER_COINCIDING");
        case "Mageskifte":
          return this.$t("COMPANY_TRANS_HANDOVER_SPOUSE");
        case "Almindelig fri handel særlige vilkår":
          return this.$t("COMPANY_TRANS_HANDOVER_SPECIAL");
        case "Familieoverdragelse":
          return this.$t("COMPANY_TRANS_HANDOVER_FAMILY");
        case "Ikke oplyst":
          return this.$t("COMPANY_TRANS_HANDOVER_UNKNOWN");
        default:
          this.$t("COMPANY_TRANS_HANDOVER_UNKNOWN");
      }
    },

    navigateToCompany(company) {
      const search = {
        id: company.cvrNumber,
        searchTerm: company.name,
      };

      this.$store.dispatch("search/initiateCompanySearch", { search, track: "transaction card" });
    },

    navigateToPerson(id) {
      if (id) {
        this.$router.push({
          name: "explore-person-id-ownership",
          params: { id: id },
        });
      }
    },

    navigateToProperty(address) {
      const search = {
        id: address.bfeNumber,
        searchTerm: `${address.streetName} ${address.streetNumber} ${address.postalCode} ${address.city}`,
      };

      this.$store.dispatch("search/initiateAddressSearch", { search, track: "transaction card" });
    },
  },
};
